import whiteLogoImage from "./../../assets/images/logo-white.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import {faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import Anchor from "../anchor/Anchor";
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import "./footer.css";

function Footer() {
    return (
        <>
            <Anchor divId={"contact"} />
            <footer>

                <div className="row container mx-auto ">
                    <div className="col-lg-3 col-md-6 pt-5 text-center">
                        <a className="navbar-brand px-5 py-0" href="#home">
                            <img src={whiteLogoImage} alt="" width="250" height="190" />

                        </a>
                    </div>
                    <div className="col-lg-4 col-md-6  " style={{ paddingTop: "65px" }}>
                        <h3 className="text-light fs-5 text-center">Plot No.10, block 12009, <br />First Industrial zone , <br />Obour city.
                        </h3>
                        <div className="social-buttons mt-4 text-center">
                            <a href="https://www.facebook.com/lavidasauce?_rdc=1&_rdr" className="social-icon mx-2  " target="_blank" rel="noreferrer">
                                <FontAwesomeIcon size="xl" icon={faFacebook} />
                            </a>
                            <a href="https://www.instagram.com/lavida.sauce/?hl=en" className="social-icon mx-2 " target="_blank" rel="noreferrer">
                                <FontAwesomeIcon size="xl" icon={faInstagram} />
                            </a>
                            <a href="https://www.linkedin.com/company/lavidasauces" className="social-icon mx-2 " target="_blank" rel="noreferrer">
                                <FontAwesomeIcon size="xl" icon={faLinkedin} />
                            </a>
                            <a href="tel:15725" className="social-icon mx-2" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon size="xl" icon={faPhone} />
                            </a>
                        </div>
                       <div className="mt-3 text-center">
                       <Link to={`contact`}><button className="contact-us-button mb-4 mx-auto"><FontAwesomeIcon icon={faEnvelope} /> Send a Message</button></Link>
                       </div>
                    </div>
                    <div className="col-lg-4 col-md-12 p-4">

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3448.359686182136!2d31.45110997622771!3d30.198279974846518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458057ee8bb3831%3A0xabd49746bbbac1d9!2sFirst%20Mix%20company%20(Lavida%20sauce)!5e0!3m2!1sen!2seg!4v1709954552803!5m2!1sen!2seg" width="100%" height="300px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                </div>

            </footer>
        </>


    );
}

export default Footer;