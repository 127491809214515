export default function ErrorPage() {


  return (
    <div className="h-100 w-100 p-5 m-5 text-center">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>

    </div>
  );
}