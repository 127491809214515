import horecaImage from "./../../assets/images/horeca.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import 'animate.css/animate.min.css';

import "./about.css";
function AboutUs() {
  return (
    <section className="about-us row container px-lg-5 px-md-2 mx-auto my-5 text-center">
      <div className="about-us-image col-lg-6 col-md-12 col-sm-12 animate__animated animate__fadeInLeft">
        <img src={horecaImage} alt="About Us" />
      </div>
      <div className="about-us-content col-lg-6 col-md-12 col-sm-12 animate__animated animate__fadeInRight">
        <h2 className="about-us-title">About Us</h2>
        <p className="about-us-text">
          Lavida for Food Manufacturing was founded in 2016 to cover the Egyptian market's need for Sauces and Sweets of the best quality to match Egyptian taste. Lavida for Food Manufacturing is also involved in the rapid growth of domestic and foreign trade in food industries in Egypt.
        </p>
        <Link to={`about`}><button className="about-us-button mb-4"><FontAwesomeIcon icon={faCircleInfo} /> Read More</button></Link>
      </div>
    </section>
  );
}

export default AboutUs;