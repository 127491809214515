
import './home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faSortAmountUpAlt, faUtensils } from "@fortawesome/free-solid-svg-icons";
import MainSlider from '../../components/slider/Slider';
import AboutUs from '../../components/about/AboutUs.jsx';
import Anchor from '../../components/anchor/Anchor.jsx';
import ProductGallery from "../../components/gallery/ProductGallery.jsx";
import 'animate.css/animate.min.css';
import { useEffect } from 'react';
function Feature(props) {
    return (
      <div className="animate__animated animate__fadeInUp col-lg-3 col-sm-12 col-md-6 justify-content-evenly d-flex my-3 mx-3 px-3 py-3 bg-white align-items-center rounded" >
        <FontAwesomeIcon size="3x" color="#d52029" icon={props.icon} />
        <div className="mx-2">
          <h5 className="p-0 m-0">{props.title}</h5>
          {/* <p>Affordable Prices</p> */}
        </div>
      </div>
  
    )
  };
function HomePage(){
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return(
        <div>
        <Anchor divId={"home"}/>
        <MainSlider />
        <Anchor divId={"about"}/>
        <AboutUs />
        <div className="features">
          <div className="row mx-auto d-flex justify-content-center container">
            <Feature title="Affordable Prices" icon={faCoins} />
            <Feature title="High Quality" icon={faSortAmountUpAlt} />
            <Feature title="Great Taste" icon={faUtensils} />
  
          </div>
        </div>
        <Anchor divId={"products"}/>
        <ProductGallery isHomePage={true}/>
       
  
      </div>
        )
}

export default HomePage;