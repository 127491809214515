import image1 from "./../../assets/images/sliders/1.jpg";
import image2 from "./../../assets/images/sliders/2.jpg";
import image3 from "./../../assets/images/sliders/3.jpg";
import image4 from "./../../assets/images/sliders/4.jpg";
import "./slider.css";
import 'animate.css/animate.min.css';


function MainSlider() {
    return (
        <div id="carouselExampleControls" className="carousel  slide" data-bs-ride="carousel">
            <div className="carousel-inner  animate__animated animate__zoomIn">
                <div className="carousel-item active">
                    <img src={image1} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item ">
                    <img src={image2} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item ">
                    <img src={image3} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item ">
                    <img src={image4} className="d-block w-100" alt="..." />
                </div>


            </div>


            <button className="carousel-control-prev carousel-arrow my-auto mx-3" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span className="carousel-control-prev-icon m-0 p-0" ></span>
                <span className="visually-hidden">Previous</span>
            </button>


            <button className="carousel-control-next carousel-arrow my-auto mx-3" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span className="carousel-control-next-icon m-0 p-0" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export default MainSlider;