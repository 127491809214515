
import './products.css';
import ProductGallery from '../../components/gallery/ProductGallery';
import { useEffect } from 'react';
function ProductsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <div className='pt-5 mt-5'>

        <ProductGallery isHomePage={false}/>
    </div>


    </>
  )
}

export default ProductsPage;