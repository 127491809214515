import logoImage from "./../../assets/images/logo.png";
import { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import "./header.css";

function Header() {
    const pathName = useLocation().pathname.split('/').pop();
    const [activeNavLink, setActiveNavLink] =
        useState(pathName || 'home');
    const location = useLocation();
    const isHomePage = location.pathname === '/';
    return (

        <nav className="navbar fixed-top navbar-expand-lg px-lg-5 py-0 navbar-light">
            <div className="container-fluid px-lg-5 d-flex ">
                <a className="navbar-brand px-lg-5 py-0" href="#home">
                    <img src={logoImage} alt="" width="140" height="100" />
                </a>
                <div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className={`nav-item mx-3 fs-5`}>
                                <Link className={`nav-link ${activeNavLink === 'home' && 'activeNavLink'}`} to={isHomePage ? "#home" : "/"}>Home</Link>
                            </li>
                            <li className={`nav-item mx-3 fs-5`}>
                                <Link className={`nav-link ${activeNavLink === 'about' && 'activeNavLink'}`} to={isHomePage ? "#about" : "/about"}>About Us</Link>

                            </li>
                            <li className={`nav-item mx-3 fs-5`}>
                            <Link className={`nav-link ${activeNavLink === 'products' && 'activeNavLink'}`} to={isHomePage ? "#products" : "/products"}>Products</Link>
                               
                            </li>
                            <li className={`nav-item mx-3 fs-5`}>
                            <Link className={`nav-link ${activeNavLink === 'contact' && 'activeNavLink'}`} to={isHomePage ? "#contact" : "/contact"}>Contact Us</Link>
                            </li>    
                        </ul>
                    </div>
                </div>
            </div>
        </nav>

    );
}

export default Header;