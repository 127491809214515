
import './about.css';
import { useEffect } from 'react';
function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container">
        <div className="text-center my-5">
          <h1 className='aboutTitle text-center'>About Us</h1>
          <h2 className="mt-4">Lavida Sauces</h2>
          <p className="lead mt-4">Lavida for Food Manufacturing was founded in 2016 to cover the Egyptian market's need for Sauces and Sweets of the best quality to match Egyptian taste. Lavida for Food Manufacturing is also involved in the rapid growth of domestic and foreign trade in food industries in Egypt.</p>
        </div>
        <div className="row">
          <div className="col-md-5 col-lg-5 col-11 my-2 text-center border border-2 rounded mx-auto py-4 px-5">
            <h4 className="mt-1">Our Vision</h4>
            <p>To be the favorite food brand in the field of Sauces and Sweets in the Arab world and Africa.</p>
          </div>
          <div className="col-md-5 col-lg-5 col-11 my-1 text-center border border-2 rounded mx-auto py-4 px-5">
            <h4 className="mt-1">Our Mission</h4>
            <p>We seek to produce and develop a new and innovative set of nutritional products for all ages and create a new experience for consumers through an integrated team working through our company's goals and values</p>
          </div>
        </div>
      </div>


    </>
  )
}

export default AboutPage;