import "./../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./../node_modules/bootstrap/dist/js/bootstrap.min.js";
import './App.css';
import { useEffect } from "react";
import ErrorPage from "./pages/Error/Error";
import AboutPage from "./pages/About/About.jsx";
import ContactPage from "./pages/Contact/Contact.jsx";
import ProductsPage from "./pages/Products/Products.jsx";
import Header from './components/header/Header';
import Footer from "./components/footer/Footer.jsx";
import { BrowserRouter as Router,useLocation, Routes, Route } from 'react-router-dom';

import HomePage from "./pages/Home/Home.jsx";



function App() {

  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}








export default App;
