
import './contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from 'react';
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";

function ContactPage() {
  const openLink = (link) => {
    window.open(link, "_blank");
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="mainDiv">
        <div className="col-12">
          <h1 className="text-center">Get In Touch</h1>
        </div>
      </div>
      <div  className="card container shadow-lg p-5">
        <div className="row g-0">
          <div onClick={() => openLink("mailto:customers@firstmix.com")} className="col-md-4 info-card">
            <div className="card-body text-center">
              <FontAwesomeIcon className="cardIcon" icon={faEnvelope} />
              <p className="card-text">customers@firstmix.com</p>
            </div>
          </div>
          <div onClick={() => openLink("tel:15725")} className="col-md-4 info-card">
            <div className="card-body text-center">
              <FontAwesomeIcon className="cardIcon" icon={faPhone} />
              <p className="card-text">15725</p>
            </div>
          </div>
          <div onClick={() => openLink("https://www.google.com/maps/place/First+Mix+company+(Lavida+sauce)/@30.19828,31.453685,15z/data=!4m5!3m4!1s0x0:0xabd49746bbbac1d9!8m2!3d30.19828!4d31.4536849?hl=en-GB")} className="col-md-4 info-card">
            <div className="card-body text-center">
              <FontAwesomeIcon className="cardIcon" icon={faLocationDot} />
              <p className="card-text">Plot no.10, block 12009, First Industrial zone , Obour city.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto  mt-5">
        <form className="row px-lg-5 px-3 needs-validation" >
          <div className="col-12 col-lg-8 mx-auto my-3">
            <label htmlFor="validationCustom01" className="form-label">Name</label>
            <input required type="text" className="form-control" id="validationCustom01" placeholder="Name" required />
            <div className="valid-feedback">
              Looks good!
            </div>
          </div>
          <div className="col-12 col-lg-8 mx-auto my-3">
            <label htmlFor="validationCustom02" className="form-label">Email</label>
            <input required type="email" className="form-control" id="validationCustom02" placeholder="Email" required />
            <div className="valid-feedback">
              Looks good!
            </div>
          </div>
          <div className="col-12 col-lg-8 mx-auto my-3">
            <label htmlFor="validationTextarea" className="form-label">Message</label>
            <textarea required className="form-control" id="validationTextarea" placeholder="Message" rows="3" required></textarea>
            <div className="valid-feedback">
              Looks good!
            </div>
          </div>
          <div className="row">
            <button className="btn formButton" type="submit">Submit form</button>
          </div>
        </form>
      </div>
    </>
  )
}

export default ContactPage;