import React, { useState, useEffect } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { Modal, ModalBody, } from 'reactstrap';
import 'animate.css/animate.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from "@fortawesome/free-solid-svg-icons";import "./gallery.css";
import { Link } from 'react-router-dom';
const ProductGallery = ({ isHomePage = true }) => {
  const [activeTab, setActiveTab] = useState('sauces-tab');
  const [modal, setModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const saucesImages = importAll(require.context('./../../assets/images/products/sauces/', false, /\.(png|jpe?g|svg)$/));
  const sweetsImages = importAll(require.context('./../../assets/images/products/sweets/', false, /\.(png|jpe?g|svg)$/));
  const syrupImages = importAll(require.context('./../../assets/images/products/syrup/', false, /\.(png|jpe?g|svg)$/));
 
  const sweetsData = [
    { id: 12, imageUrl: sweetsImages['agave.png'], productName: 'Agave Nectar' },
    { id: 8, imageUrl: sweetsImages['coffee.png'], productName: 'Coffee Chocolate' },
    { id: 9, imageUrl: sweetsImages['hazelnut.png'], productName: 'Hazelnut Chocolate' },
    { id: 10, imageUrl: sweetsImages['strawberry.png'], productName: 'Strawberry Chocolate' },
    { id: 11, imageUrl: sweetsImages['white.png'], productName: 'White Chocolate' },
    { id: 13, imageUrl: sweetsImages['mint.png'], productName: 'Mint Chocolate' },
    { id: 14, imageUrl: sweetsImages['dark.png'], productName: 'Dark Chocolate' },

  ];
const saucesData = [
  { id: 29, imageUrl: saucesImages['bbq.png'], productName: 'BBQ Sauce' },
  { id: 33, imageUrl: saucesImages['ranch.png'], productName: 'Ranch Sauce' },
  { id: 30, imageUrl: saucesImages['ketchup.png'], productName: 'Ketchup' },
  { id: 31, imageUrl: saucesImages['Mustard.png'], productName: 'Mustard' },
  { id: 28, imageUrl: saucesImages['cheddar.png'], productName: 'Cheddar Sauce' },
  { id: 19, imageUrl: saucesImages['texas.png'], productName: 'Texas Sauce' },
  { id: 16, imageUrl: saucesImages['toumaya.png'], productName: 'Toumaya Sauce' },
  { id: 23, imageUrl: saucesImages['lemon.png'], productName: 'Lemon Sauce' },
  { id: 17, imageUrl: saucesImages['thousand.png'], productName: 'Thousand Island Sauce' },
  { id: 18, imageUrl: saucesImages['thai.png'], productName: 'Thai Sauce' },
  { id: 15, imageUrl: saucesImages['pomegranate_molasses.png'], productName: 'Pomegranate Molasses Sauce' },
  { id: 20, imageUrl: saucesImages['sweet_chilli.png'], productName: 'Sweet & Chilli Sauce' },
  { id: 22, imageUrl: saucesImages['mayo_classic.png'], productName: 'Mayo Classic Sauce' },
  { id: 24, imageUrl: saucesImages['honey_mustard.png'], productName: 'Honey Mustard Sauce' },
  { id: 25, imageUrl: saucesImages['harrisa.png'], productName: 'Harrisa Sauce' },
  { id: 26, imageUrl: saucesImages['fire.png'], productName: 'Fire Sauce' },
  { id: 27, imageUrl: saucesImages['chilli_mayo.png'], productName: 'Chilli Mayo Sauce' },
  { id: 32, imageUrl: saucesImages['Pizza.png'], productName: 'Pizza Sauce' },
  { id: 21, imageUrl: saucesImages['mustard_1_kg.png'], productName: 'Mustard 1 Kg Sauce' },
  { id: 34, imageUrl: saucesImages['Rizo_1_kg.png'], productName: 'Rizo Sauce 1 Kg' },
];
  const syrupsData = [
    { id: 1, imageUrl: syrupImages['caramel.png'], productName: 'Caramel Syrup' },
    { id: 2, imageUrl: syrupImages['cherry.png'], productName: 'Cherry Syrup' },
    { id: 3, imageUrl: syrupImages['coffee.png'], productName: 'Coffee Syrup' },
    { id: 4, imageUrl: syrupImages['hazelnut.png'], productName: 'Hazelnut Syrup' },
    { id: 5, imageUrl: syrupImages['strawberry.png'], productName: 'Strawberry Syrup' },
    { id: 6, imageUrl: syrupImages['toffee.png'], productName: 'Toffee Syrup' },
    { id: 7, imageUrl: syrupImages['vanilla.png'], productName: 'Vanilla Syrup' },
  ];


  useEffect(() => {
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setModal(true);
  };

  const handleModalClose = () => {
    setSelectedProduct(null);
    setModal(false);
  };

  const renderProductCards = (data,isAllTab = false) => {
    let productsCards = isHomePage ? (data.slice(0, 10)) : data;

    return productsCards.map((product) => (
      <div onClick={() => handleProductClick(product)} className={isAllTab?'product-image col-lg-2 col-md-6 col-sm-12':'product-image col-lg-3 col-md-6 col-sm-12'} key={product.id}>
        <div className='product-name-overlay p-0 '>
          <p className='product-name'>{product.productName}</p>
        </div>
        <img src={product.imageUrl} width="95%" height="100%" alt={product.productName} />
      </div>
    ));

  }
  return (
    <>
      <div className="container pt-5  mb-5">
        <h1 className='productsTitle'>Our Products</h1>
        <ul className="container row animate__animated animate__fadeInUp  d-flex mx-auto  ">
          <li className="offset-lg-4 px-lg-5 px-md-5 px-4 offset-md-3 col-lg-1 col-md-2 col-3 text-uppercase fs-lg-5 fs-md-3 fs-sm-2 ">
            <button
              className={`tab-button mx-auto text-center text-uppercase fs-lg-5 fs-md-3 fs-sm-2 ${activeTab === 'all' ? 'active-tab-button' : ''}`} aria-current="page"
              onClick={() => handleTabClick('all')}
            >ALL</button>
          </li>
          <li className=" col-lg-1 col-md-2 col-3 text-uppercase fs-lg-5 fs-md-3 fs-sm-2 ">
            <button
              className={`tab-button mx-auto text-center text-uppercase fs-lg-5 fs-md-3 fs-sm-2 ${activeTab === 'sauces-tab' ? 'active-tab-button' : ''}`} aria-current="page"
              onClick={() => handleTabClick('sauces-tab')}
            >Sauces</button>
          </li>
          <li className=" col-lg-1 col-md-2 col-3 text-uppercase fs-lg-5 fs-md-3 fs-sm-2 ">
            <button
              className={`tab-button mx-auto text-center text-uppercase fs-lg-5 fs-md-3 fs-sm-2 ${activeTab === 'sweets-tab' ? 'active-tab-button' : ''}`} aria-current="page"
              onClick={() => handleTabClick('sweets-tab')}
            >Sweets</button>
          </li>
          <li className=" col-lg-1 col-md-2 col-3 text-uppercase fs-lg-5 fs-md-3 fs-sm-2 ">
            <button
              className={`tab-button mx-auto text-center text-uppercase fs-lg-5 fs-md-3 fs-sm-2 ${activeTab === 'syrups-tab' ? 'active-tab-button' : ''}`} aria-current="page"
              onClick={() => handleTabClick('syrups-tab')}
            >Syrups</button>
          </li>

        </ul>
        <TabContent className='my-5 row' activeTab={activeTab}>
          <TabPane tabId="all">
            <div className="row row-cols-lg-4 px-4">
              {renderProductCards(
                [...saucesData, ...sweetsData, ...syrupsData]
                  .sort(() => 0.5 - Math.random()) // Randomize the order
              ,true)}
            </div>
          </TabPane>
          <TabPane tabId="sauces-tab">
            <div className="row row-cols-lg-4 px-4">{renderProductCards(saucesData)}</div>
          </TabPane>
          <TabPane tabId="sweets-tab">
            <div className="row row-cols-lg-4 px-4">{renderProductCards(sweetsData)}</div>
          </TabPane>
          <TabPane tabId="syrups-tab">
            <div className="row row-cols-lg-4 px-4">{renderProductCards(syrupsData)}</div>
          </TabPane>
        </TabContent>
        {isHomePage &&
          <div className="container text-center">
            <Link to={`products`}>
              <button className='see-all-button mx-auto'>See All Products</button>
            </Link>
          </div>
        }
      </div>

      <Modal isOpen={selectedProduct !== null} toggle={handleModalClose} >
        <div className="text-end pt-3 ">
          <FontAwesomeIcon size='2x' onClick={handleModalClose} className='closeModalIcon' icon={faClose} />
        </div>  
        <ModalBody className='p-0'>
          <img src={selectedProduct?.imageUrl} alt={selectedProduct?.productName} />
          <h1 className='mx-auto my-1 text-center'>{selectedProduct?.productName}</h1>
        </ModalBody>
      </Modal>
      </>
  );
};

export default ProductGallery;